import React, { useState } from 'react'
import { Table, TextField, Typography } from '../../components/Atoms'
import { Box } from '@mui/material'
import { Page } from '../../components/Page'
import { PageTitle } from '../../components/PageTitle'
import { useTranslations } from '../../context/TranslationsContext'

const TYPES = [
    "fighting",
    "flying",
    "poison",
    "ground",
    "rock",
    "bug",
    "ghost",
    "steel",
    "fire",
    "water",
    "grass",
    "electric",
    "psychic",
    "ice",
    "dragon",
    "dark"
]

const getType = (hp, atk, def, spa, spd, spe) => {
    const isOdd = (n) => {
        return n % 2 !== 0;
    }

    let a = isOdd(hp)  ? 1  : 0;
    let b = isOdd(atk) ? 2  : 0;
    let c = isOdd(def) ? 4  : 0;
    let d = isOdd(spe) ? 8  : 0;
    let e = isOdd(spa) ? 16 : 0;
    let f = isOdd(spd) ? 32 : 0;

    let n = a + b + c + d + e + f;

    n *= 15

    n = Math.floor(n / 63);

    return TYPES[n];
}

const getPower = (hp, atk, def, spa, spd, spe) => {
    const isOdd = (n) => {
        return n % 2!== 0;
    }

    const getBinary = (n) => {
        return n.toString(2);
    }

    const get2LSB = (n) => {
        return n.charAt(n.length-2)
    }

    let u = isOdd( get2LSB( getBinary( hp  ) ) ) ? 1  : 0;
    let v = isOdd( get2LSB( getBinary( atk ) ) ) ? 2  : 0;
    let w = isOdd( get2LSB( getBinary( def ) ) ) ? 4  : 0;
    let x = isOdd( get2LSB( getBinary( spe ) ) ) ? 8  : 0;
    let y = isOdd( get2LSB( getBinary( spa ) ) ) ? 16 : 0;
    let z = isOdd( get2LSB( getBinary( spd ) ) ) ? 32 : 0;

    let n = u + v + w + x + y + z;

    n *= 40

    n = Math.floor(n / 63);

    n += 30;

    return n;
}

const HiddenPowerCalculator = ({ pageContext }) => {
    const { t } = useTranslations()
    const PAGE_TITLE = "Hidden Power Calculator"

    const [inputs, setInputs] = useState({
        HP: 31,
        ATK: 31,
        DEF: 31,
        SPA: 31,
        SPD: 31,
        SPE: 31
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = parseInt(e.target.value, 10);
        if (value >= 1 && value <= 31) {
            setInputs({
                ...inputs,
                [name]: value
            });
        }
    };
    
    const calculateType = () => {
        return t( getType(inputs.HP, inputs.ATK, inputs.DEF, inputs.SPA, inputs.SPD, inputs.SPE) );
    };

    const calculatePower = () => {
        return getPower(inputs.HP, inputs.ATK, inputs.DEF, inputs.SPA, inputs.SPD, inputs.SPE);
    };


    return (
        <Page breadcrumbs={pageContext.breadcrumb} label={PAGE_TITLE}>
            <PageTitle>{t(PAGE_TITLE)}</PageTitle>
            <Box component="form" 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                }}>
                
                <TextField textClassName="text-muted" type="number" inputprops={{min: 0, max: 31}} label="hp" name="HP" value={inputs.HP} onChange={handleChange} />
                <TextField textClassName="text-muted" type="number" inputprops={{min: 0, max: 31}} label="attack" name="ATK" value={inputs.ATK} onChange={handleChange} />
                <TextField textClassName="text-muted" type="number" inputprops={{min: 0, max: 31}} label="defense" name="DEF" value={inputs.DEF} onChange={handleChange} />
                <TextField textClassName="text-muted" type="number" inputprops={{min: 0, max: 31}} label="sp. attack" name="SPA" value={inputs.SPA} onChange={handleChange} />
                <TextField textClassName="text-muted" type="number" inputprops={{min: 0, max: 31}} label="sp. defense" name="SPD" value={inputs.SPD} onChange={handleChange} />
                <TextField textClassName="text-muted" type="number" inputprops={{min: 0, max: 31}} label="speed" name="SPE" value={inputs.SPE} onChange={handleChange} />
            
            </Box>
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'center', 
                    mt: 2 
                }}
            >
                <Box component="form" 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        gap: 2,
                        alignItems: 'center'
                    }}>
                    <TextField textClassName="text-muted" type="text" label="type" name="type" value={calculateType()} disabled/>
                    <TextField textClassName="text-muted" type="number" label="power" name="power" value={calculatePower()} disabled/>
                </Box>
            </Box>
            <Typography as="h5">Highest IVs per type</Typography>
            <Box sx={{
                mt: 2,
            }}>
                <Table size='sm' striped="columns" className="IVtable" responsive>
                    <thead>
                        <tr>
                            <th>{t('type')}</th>
                            <th>{t('hp')}</th>
                            <th>{t('attack')}</th>
                            <th>{t('defense')}</th>
                            <th>{t('sp. attack')}</th>
                            <th>{t('sp. defense')}</th>
                            <th>{t('speed')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <a class="type-icon type-fire type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/fire" rel="noreferrer">{t('fire')}</a>
                            </td>
                            <td>31</td>
                            <td>30</td>
                            <td>31</td>
                            <td>30</td>
                            <td>31</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-water type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/water" rel="noreferrer">{t('water')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>31</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-electric type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/electric" rel="noreferrer">{t('electric')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>31</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-grass type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/grass" rel="noreferrer">{t('grass')}</a>
                            </td>
                            <td>30</td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>31</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-ice type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/ice" rel="noreferrer">{t('ice')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-fighting type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/fighting" rel="noreferrer">{t('fighting')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>30</td>
                            <td>30</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-poison type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/poison" rel="noreferrer">{t('poison')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>30</td>
                            <td>30</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-ground type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/ground" rel="noreferrer">{t('ground')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>30</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-flying type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/flying" rel="noreferrer">{t('flying')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>30</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-psychic type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/psychic" rel="noreferrer">{t('psychic')}</a>
                            </td>
                            <td>30</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-bug type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/bug" rel="noreferrer">{t('bug')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-rock type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/rock" rel="noreferrer">{t('rock')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>31</td>
                            <td>30</td>
                            <td>30</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-ghost type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/ghost" rel="noreferrer">{t('ghost')}</a>
                            </td>
                            <td>31</td>
                            <td>30</td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-dragon type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/dragon" rel="noreferrer">{t('dragon')}</a>
                            </td>
                            <td>30</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-dark type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/dark" rel="noreferrer">{t('dark')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                        </tr>
                        <tr>
                            <td>
                                <a class="type-icon type-steel type-cell hpc-cell" target="_blank" href="https://pokemondb.net/type/steel" rel="noreferrer">{t('steel')}</a>
                            </td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>31</td>
                            <td>30</td>
                            <td>31</td>
                        </tr>
                    </tbody>
                </Table>
            </Box>
        </Page>
    )
}

export default HiddenPowerCalculator